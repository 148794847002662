@import 'variables';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../app-base';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '../../css/muli.css';

#main {
    background-image: url("../images/background.jpg");
}

.main-button {
    background-color: #D22D64;
    border-color: #D22D64;
}

.main-button:hover {
    background-color: #A0204C;
    border-color: #D22D65;
}
